<template>
  <div class="exam-content">
    <div class="row">
      <div class="col-md-12">
        <div class="exam-wrap">
          <div class="__header">
            <h4>{{ quiz.title }} Result</h4>
            <p>TEST INSTRUCTION : CLICK OPTION (A, B, C, D)</p>
          </div>
          <div class="__body" v-if="isAlreadyAttended == false">
            <h5 v-if="isLoading == false">Question {{ questionIndex + 1 }}</h5>
            <p class="question" v-html="question.question"></p>
            <div class="answer-list" v-if="isLoading == false">
              <ul class="list-unstyled">
                <li
                  v-for="(answer, answerIndex) in question.options"
                  :key="answerIndex"
                >
                  <a
                    href="#"
                    :class="[
                      answer.id == selectedAnswer ? 'marked-answer' : '',
                      answer.is_right_answer == 1 ? 'active' : ''
                    ]"
                    ><span v-if="answerIndex == 0">A</span
                    ><span v-if="answerIndex == 1">B</span
                    ><span v-if="answerIndex == 2">C</span
                    ><span v-if="answerIndex == 3">D</span></a
                  >
                  <p>{{ answer.option }}</p>
                </li>
              </ul>
            </div>
            <webinar-loader v-else></webinar-loader>

            <div class="button-wrap">
              <button
                class="cta-primary btn"
                v-if="questionIndex > 0"
                @click.prevent="previousQuestion()"
              >
                Previous
              </button>
              <button
                class="cta-primary btn"
                v-if="questionIndex < questions.length - 1"
                @click.prevent="nextQuestion()"
              >
                Next
              </button>
            </div>
          </div>
          <div class="__body" v-else>
            <p class="message-text">{{ message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuizService from "@/services/QuizService";
import WebinarLoader from "@/components/loaders/WebinarLoader";
export default {
  name: "QuizResult",
  components: { WebinarLoader },
  created() {
    this.getQuizResult();
  },
  data() {
    return {
      quiz: [],
      questions: [],
      questionIndex: 0,
      question: "",
      answers: [],
      selectedAnswer: "",
      selectedAnswerArray: [],
      attendedQuestion: [],
      isSubmittingExam: false,
      isAlreadyAttended: false,
      isLoading: true,
      message: "",
      quizId: this.$route.params.id
    };
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isNestMember"]);
      return this.$store.getters["privilege/isNestMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isNestMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  methods: {
    getQuizResult: function() {
      this.isLoading = true;
      QuizService.getQuizResult({
        quiz_id: this.quizId
      })
        .then(result => {
          this.isLoading = false;
          if (result.data.status == "success") {
            this.questions = result.data.questions;
            this.quiz = result.data.quiz;
            this.getQuestion();
          }
        })
        .catch(error => {
          this.error = error;
        });
    },
    getQuestion: function() {
      if (this.questions.length > 0) {
        this.question = this.questions[this.questionIndex];
        this.selectedAnswer = this.question.marked_answer;
      } else {
        this.question = "";
      }
    },

    nextQuestion() {
      this.questionIndex += 1;
      this.getQuestion();
    },
    previousQuestion() {
      this.questionIndex -= 1;
      this.getQuestion();
    }
  }
};
</script>

<style lang="scss">
@import "@/style/exam-portal.scss";
</style>
